<template>
  <div v-if="user.roles.includes('ADMIN')" class="col-1">
    <br/>
    <div class="row">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" :class="{active: activeComponent === 'requests' }" aria-current="page" href="#"
             @click="activeComponent = 'requests'">Заявки</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{active: activeComponent === 'blockcypher' }" aria-current="page" href="#"
             @click="activeComponent = 'blockcypher'">BTC транз.</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{active: activeComponent === 'ltc_tx' }" aria-current="page" href="#"
             @click="activeComponent = 'ltc_tx'">LTC транз.</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{active: activeComponent === 'xmr_tx' }" aria-current="page" href="#"
             @click="activeComponent = 'xmr_tx'">XMR транз.</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{active: activeComponent === 'eth_tx' }" aria-current="page" href="#"
             @click="activeComponent = 'eth_tx'">ETH транз.</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{active: activeComponent === 'trx_tx' }" aria-current="page" href="#"
             @click="activeComponent = 'trx_tx'">Tron транз.</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{active: activeComponent === 'news' }" aria-current="page" href="#"
             @click="activeComponent = 'news'">Новости</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{active: activeComponent === 'paymentSystems' }" aria-current="page" href="#"
             @click="activeComponent = 'paymentSystems'">Платежные системы</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{active: activeComponent === 'pairs' }" aria-current="page" href="#"
             @click="activeComponent = 'pairs'">Валютные пары</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{active: activeComponent === 'bankCards' }" aria-current="page" href="#"
             @click="activeComponent = 'bankCards'">Банковские карты
            <span v-if="state.bankCards > 0" class="badge badge-pill badge-danger">{{ state.bankCards }}</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{active: activeComponent === 'referralWithdrawalRequests' }" aria-current="page"
             href="#" @click="activeComponent = 'referralWithdrawalRequests'">Выводы реф.
            <span v-if="state.referralWithdrawalRequests > 0"
                  class="badge badge-pill badge-danger">{{ state.referralWithdrawalRequests }}</span>
          </a>
        </li>
      </ul>
    </div>
    <br/>
    <component v-bind:is="component"></component>
  </div>
</template>

<script>

import Requests from "@/views/admin/Requests";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

const REQUESTS_VIEW = () => import("@/views/admin/Requests")
const BLOCKCYPHER_VIEW = () => import("@/views/admin/Blockcypher")
const LTC_TX_VIEW = () => import("@/views/admin/LtcTransactions")
const XMR_TX_VIEW = () => import("@/views/admin/XmrTransactions")
const ETH_TX_VIEW = () => import("@/views/admin/EthTransactions")
const TRX_TX_VIEW = () => import("@/views/admin/TrxTransactions")
const NEWS_VIEW = () => import("@/views/admin/News")
const PAYMENT_SYSTEMS_VIEW = () => import("@/views/admin/PaymentSystems")
const PAIRS_VIEW = () => import("@/views/admin/Pairs")
const BANK_CARDS_VIEW = () => import("@/views/admin/BankCards")
const REFERRAL_WITHDRAWAL_REQUESTS_VIEW = () => import("@/views/admin/ReferralWithdrawalRequests")

export default {
  name: 'Admin',
  components: {Requests},

  data: function () {
    return {
      activeComponent: "requests",
      state: {
        bankCards: 0,
        referralWithdrawalRequests: 0
      }
    }
  },

  computed: {
    ...mapGetters(['user', 'token']),

    component() {
      let result
      switch (this.activeComponent) {
        case "requests":
          result = REQUESTS_VIEW;
          break
        case "pairs":
          result = PAIRS_VIEW;
          break
        case "blockcypher":
          result = BLOCKCYPHER_VIEW;
          break
        case "ltc_tx":
          result = LTC_TX_VIEW;
          break
        case "xmr_tx":
          result = XMR_TX_VIEW;
          break
        case "eth_tx":
          result = ETH_TX_VIEW;
          break
        case "trx_tx":
          result = TRX_TX_VIEW;
          break
        case "paymentSystems":
          result = PAYMENT_SYSTEMS_VIEW;
          break
        case "news":
          result = NEWS_VIEW;
          break
        case "bankCards":
          result = BANK_CARDS_VIEW;
          break
        case "referralWithdrawalRequests":
          result = REFERRAL_WITHDRAWAL_REQUESTS_VIEW;
          break
        default:
          result = REQUESTS_VIEW;
      }
      return result;
    }
  },

  methods: {
    ...mapActions(["fetchUser"])
  },

  async mounted() {
    if (!this.user) {
      try {
        await this.fetchUser();

      } catch (e) {
        await this.$router.push('/')
      }
    }

    axios.get('/api/admin/state', {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(
        response => {
          this.state = response.data
        }
    );

    if (this.user === null) {
      await this.$router.push('/')
      return
    }

    if (!this.user.roles.includes('ADMIN')) {
      await this.$router.push('/')
    }
  }
}
</script>