<template>
  <div>
    <b-modal id="modal-requisites" centered
             title="Реквизиты"
    >
      <b-form>
        <div>Email: {{ request.email }}</div>
        <div>Статус: {{ request.status }}</div>
        <div>Время создания: {{ request.createdDate }}</div>
        <div>Вх. реквизиты:</div>
        <pre>{{ request.srcRequisites | pretty }}</pre>
        <br>
        <div>Исх. реквизиты:</div>
        <pre>{{ request.dstRequisites | pretty }}</pre>
        <br>
        <div>Прочее:</div>
        <pre>{{ request.data | pretty }}</pre>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'RequisitesModalForm',
  props: ['request'],


  filters: {
    pretty: function (json) {
      return JSON.stringify(json, undefined, 2)
    }
  }

}
</script>

