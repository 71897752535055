<template>
  <div>
    <RequisitesModalForm :request="currentRequest"></RequisitesModalForm>
    <BPagination
        align="center"
        v-model="page"
        :total-rows="history.totalElements"
        :per-page="history.size"
        @change="changePage"
    />
    <br>
    <table class="table table-striped">
      <caption>Request history table</caption>
      <thead>
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Статус</th>
        <th scope="col">Пара</th>
        <th scope="col">Сумма вх.</th>
        <th scope="col">Сумма исх.</th>
        <th scope="col">Дата</th>
        <th scope="col">Реквизиты</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="req in history.content" v-bind:key="req.id">
        <td><a :href="'/payment/' + req.uuid"
               target="_blank"
               rel="noopener noreferrer">
          {{ req.id }}
        </a></td>
        <td>
          <span v-if="req.status === 'NEW'" class="badge badge-pill badge-primary">Новая</span>
          <span v-if="req.status === 'FOR_PAYMENT'" class="badge badge-pill badge-secondary">Ожидает оплаты</span>
          <span v-else-if="req.status === 'PAYED'" class="badge badge-pill badge-info">Оплачено</span>
          <span v-else-if="req.status === 'FOR_TRANSFER'" class="badge badge-pill badge-warning">Ожидает выплаты</span>
          <span v-else-if="req.status === 'DONE'" class="badge badge-pill badge-success">Завершена</span>
          <span v-else-if="req.status === 'ERROR'" class="badge badge-pill badge-danger">Ошибка</span>
          <span v-else-if="req.status === 'EXPIRED'" class="badge badge-pill badge-light">Просрочена</span>
          <div v-if="['EXPIRED', 'DONE', 'ERROR'].indexOf(req.status) > -1" class="btn-link"
               @click="resetToPayed(req.id)">Проверка оплаты</div>
          <div v-if="['EXPIRED', 'DONE', 'ERROR', 'PAYED', 'FOR_PAYMENT'].indexOf(req.status) > -1" class="btn-link"
               @click="resetToForTransfer(req.id)">Подтвердить</div>
          <div v-if="['EXPIRED', 'PAYED', 'FOR_PAYMENT', 'NEW', 'FOR_TRANSFER'].indexOf(req.status) > -1" class="btn-link"
               @click="resetToError(req.id)">В ошибку</div>
          <div v-if="['EXPIRED', 'PAYED', 'FOR_PAYMENT', 'NEW', 'FOR_TRANSFER'].indexOf(req.status) > -1" class="btn-link"
               @click="resetToDone(req.id)">В завершено</div>
        </td>
        <td>{{ req.srcPaySystem.name }} - {{ req.dstPaySystem.name }}</td>
        <td>{{ req.srcAmount }} {{ req.srcPaySystem.currency.isoCode }}</td>
        <td>{{ req.dstAmount }} {{ req.dstPaySystem.currency.isoCode }}</td>
        <td>{{ req.createdDate | moment("DD.MM.YYYY") }}</td>
        <td>
          <div class="btn-link" @click="currentRequest = req" v-b-modal.modal-requisites>Просмотр</div>
        </td>
      </tr>
      </tbody>
    </table>
    <br>
    <BPagination
        align="center"
        v-model="page"
        :total-rows="history.totalElements"
        :per-page="history.size"
        @change="changePage"
    />
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import RequisitesModalForm from "@/components/modal/RequisitesModalForm";

export default {
  name: 'RequestHistory',
  components: {RequisitesModalForm},
  props: ['type'],

  data: function () {
    return {
      updateRequestPolling: null,
      page: 1,
      history: {},
      currentRequest: {},
    }
  },

  computed: {
    ...mapGetters(['user', 'token']),
  },

  watch: {
    type: function () {
      this.fetch()
    }
  },

  methods: {
    ...mapActions(["setMaintaining"]),
    fetch: function (page) {
      axios.get('/api/admin/history?page=' + page, {
        headers: {
          'Authorization': 'Bearer ' + this.token
        }
      }).then(
          response => {
            this.history = response.data
          }
      );
    },
    changePage: async function (newPage) {
      this.page = newPage;
      this.fetch(this.page);
    },
    async approveRequest(id) {

      let otp = prompt("OTP");

      const res = await axios.post('/api/admin/request/' + id + '/approve', null, {
        headers: {
          'Authorization': 'Bearer ' + this.token,
          'X-OTP-CODE': otp
        }
      })
      await res.data
    },
    async resetToPayed(id) {

      let otp = prompt("OTP");

      const res = await axios.post('/api/admin/request/' + id + '/reset_to_payed', null, {
        headers: {
          'Authorization': 'Bearer ' + this.token,
          'X-OTP-CODE': otp
        }
      })
      const data = await res.data

      if (data.result === true) {
        alert("Статус успешно сброшен");
        this.fetch(this.page);
      } else {
        alert(data.message);
      }
    },
    async resetToForTransfer(id) {

      let otp = prompt("OTP");

      const res = await axios.post('/api/admin/request/' + id + '/reset_to_for_transfer', null, {
        headers: {
          'Authorization': 'Bearer ' + this.token,
          'X-OTP-CODE': otp
        }
      })
      const data = await res.data

      if (data.result === true) {
        alert("Статус успешно сброшен");
        this.fetch(this.page);
      } else {
        alert(data.message);
      }
    },

    async resetToError(id) {

      const res = await axios.post('/api/admin/request/' + id + '/reset_to_error', null,{
        headers: {
          'Authorization': 'Bearer ' + this.token
        }})
      const data = await res.data

      if (data.result === true) {
        alert("Статус успешно сброшен");
        this.fetch(this.page);
      } else {
        alert(data.message);
      }
    },

    async resetToDone(id) {

      const res = await axios.post('/api/admin/request/' + id + '/reset_to_done', null,{
        headers: {
          'Authorization': 'Bearer ' + this.token
        }})
      const data = await res.data

      if (data.result === true) {
        alert("Статус успешно сброшен");
        this.fetch(this.page);
      } else {
        alert(data.message);
      }
    },
  },

  created() {

    if (!this.user.roles.includes('ADMIN')) {
      this.$router.push('/')
    }
    this.fetch(this.page)
  },

  async mounted() {
    this.updateRequestPolling = setInterval(() => {
      this.fetchBankCards(this.page)
    }, 30000)
  },
  destroyed() {
    clearInterval(this.updateRequestPolling)
  }
}
</script>